<template>

  <div>

    <Header @clicked="switchL()"></Header>

    <section class="intro intro_2" v-bind:style='{ backgroundImage: "url("+ intro.IntroBGUrl +")" }' id="intro">

      <div class="site__centered">

        <h1 class="intro__title" v-html="$t('homeScreen.intro.title')"></h1><!-- /intro__title -->

        <div class="site__btn">
          <a :href="links.registration" class="btn" target="_blank">
            {{ $t('buttons.signUp') }}
          </a>
        </div><!-- /site__btn -->

      </div><!-- /site__centered -->

    </section><!-- /intro -->

    <section class="why-us">

      <div class="site__centered">

        <site-title addedClass="site__title_duplicate">{{ $t('whyVideo.title') }}</site-title>

      </div><!-- /site__centered -->

      <div class="why-us__wrap">

        <div class="slider" ref="slider">

          <div class="swiper-container">

            <div class="swiper-wrapper">

              <div class="swiper-slide">

                <div class="slider__wrap">

                  <div class="slider__side">

                    <div class="slider__row" v-html="$t('whyVideo.slide1.text1')"></div><!-- /slider__row -->

                    <div class="slider__row" v-html="$t('whyVideo.slide1.text2')"></div><!-- /slider__row -->

                  </div><!-- /slider__side -->

                  <div class="slider__side">

                    <img src="../assets/new/pic/slider/slider__pic-1.png" alt="Distribune" class="slider__pic"
                         width="690">

                  </div><!-- /slider__side -->

                </div><!-- /slider__wrap -->

              </div><!-- /swiper-slide -->

              <div class="swiper-slide">

                <div class="slider__wrap">

                  <div class="slider__side">

                    <div class="slider__row" v-html="$t('whyVideo.slide2.text1')"></div><!-- /slider__row -->

                    <div class="slider__row" v-html="$t('whyVideo.slide2.text2')"></div><!-- /slider__row -->

                  </div><!-- /slider__side -->

                  <div class="slider__side">

                    <img src="../assets/new/pic/slider/slider__pic-2.png" alt="Distribune" class="slider__pic"
                         width="690">

                  </div><!-- /slider__side -->

                </div><!-- /slider__wrap -->

              </div><!-- /swiper-slide -->

              <div class="swiper-slide">

                <div class="slider__wrap">

                  <div class="slider__side">

                    <div class="slider__row" v-html="$t('whyVideo.slide3.text1')"></div><!-- /slider__row -->

                    <div class="slider__row" v-html="$t('whyVideo.slide3.text2')"></div><!-- /slider__row -->

                  </div><!-- /slider__side -->

                  <div class="slider__side">

                    <img src="../assets/new/pic/slider/slider__pic-3.png" alt="Distribune" class="slider__pic"
                         width="690">

                  </div><!-- /slider__side -->

                </div><!-- /slider__wrap -->

              </div><!-- /swiper-slide -->

            </div>

          </div>

          <div class="swiper-pagination"></div><!-- /swiper-pagination -->

        </div><!-- /slider -->

      </div><!-- /why-us__wrap -->

    </section><!-- /why-us -->

    <section class="webmaster">

      <div class="webmaster__head">

        <div class="webmaster__head-wrap">

          <site-title addedClass="site__title_duplicate site__title_left">
            {{ $t('weOffer.title') }}
          </site-title>

          <!--<site-title addedClass="site__title_duplicate site__title_left" v-html="($t('weOffer.title'))"></site-title>-->

        </div><!-- /webmaster__head-wrap -->

      </div><!-- /webmaster__head -->

      <div class="site__centered">

        <div class="webmaster__wrap">

          <h2 class="webmaster__title"><span>{{ $t('weOffer.publisher.title') }}</span></h2><!-- /webmaster__title -->

          <div class="webmaster__list">

            <div class="webmaster__list-item webmaster__list-item_1" v-html="$t('weOffer.publisher.text1')"></div>
            <!-- /webmaster__list-item -->
            <div class="webmaster__list-item webmaster__list-item_2" v-html="$t('weOffer.publisher.text2')"></div>
            <!-- /webmaster__list-item -->
            <div class="webmaster__list-item webmaster__list-item_3" v-html="$t('weOffer.publisher.text3')"></div>
            <!-- /webmaster__list-item -->
            <div class="webmaster__list-item webmaster__list-item_4" v-html="$t('weOffer.publisher.text4')"></div>
            <!-- /webmaster__list-item -->

          </div><!-- /webmaster__list -->

        </div><!-- /webmaster__wrap -->

      </div><!-- /site__centered -->

    </section><!-- /webmaster -->

    <section class="publisher">

      <div class="site__centered">

        <div class="publisher__wrap">

          <h2 class="publisher__title">{{ $t('weOffer.contentOwner.title') }}</h2><!-- /publisher__title -->

          <div class="publisher__list">

            <div class="publisher__list-item publisher__list-item_1" v-html="$t('weOffer.contentOwner.text1')"></div>
            <!-- /publisher__list-item -->
            <div class="publisher__list-item publisher__list-item_2" v-html="$t('weOffer.contentOwner.text2')"></div>
            <!-- /publisher__list-item -->

          </div><!-- /publisher__list -->

        </div><!-- /publisher__wrap -->

      </div><!-- /site__centered -->

    </section><!-- /publisher -->

    <section class="site__centered">

      <div class="site__btn">
        <a :href="links.registration" class="btn" target="_blank">
          {{ $t('buttons.signUp') }}
        </a>
      </div><!-- /site__btn -->

    </section><!-- /site__centered -->

    <section class="facts">

      <div class="site__centered">

        <site-title addedClass="site__title_duplicate">
          {{ $t('keyFacts.title') }}
        </site-title>

        <div class="facts__cover">

          <div class="facts__wrap">

            <div class="facts__item facts__item_1">
              <div v-html="$t('keyFacts.item1')"></div>
            </div><!-- /facts__item -->

            <div class="facts__item facts__item_2">
              <div v-html="$t('keyFacts.item2')"></div>
            </div><!-- /facts__item -->

            <div class="facts__item facts__item_3">
              <div v-html="$t('keyFacts.item3')"></div>
            </div><!-- /facts__item -->

            <div class="facts__item facts__item_4">
              <div v-html="$t('keyFacts.item4')"></div>
            </div><!-- /facts__item -->

          </div><!-- /facts__wrap -->

        </div><!-- /facts__cover -->

      </div><!-- /site__centered -->

    </section><!-- /facts -->

    <section class="how-works">

      <div class="site__centered">

        <site-title addedClass="site__title_duplicate site__title_left site__title_2">
          {{ $t('howWorks.title') }}
        </site-title>

        <div class="how-works__wrap">

          <div class="how-works__item how-works__item_1">

            <div class="how-works__pic">
              <img src="../assets/new/img/how-works/how-works__pic-1.png" alt="">
            </div><!-- /how-works__pic -->

            <div class="how-works__txt" v-html="$t('howWorks.item1')"></div><!-- /how-works__txt -->

          </div><!-- /how-works__item -->

          <div class="how-works__item how-works__item_2">

            <div class="how-works__pic">
              <img v-if="ru" src="../assets/new/img/how-works/how-works__pic-2.png" alt="">
              <img v-if="!ru" src="../assets/new/img/how-works/how-works__pic-2_en.png" alt="">
            </div><!-- /how-works__pic -->

            <div class="how-works__txt" v-html="$t('howWorks.item2')"></div><!-- /how-works__txt -->

          </div><!-- /how-works__item -->

          <div class="how-works__item how-works__item_3">

            <div class="how-works__pic">
              <img src="../assets/new/img/how-works/how-works__pic-3.png" alt="">
            </div><!-- /how-works__pic -->

            <div class="how-works__txt" v-html="$t('howWorks.item3')"></div><!-- /how-works__txt -->

          </div><!-- /how-works__item -->

          <div class="how-works__item how-works__item_4">

            <div class="how-works__pic">
              <img src="../assets/new/img/how-works/how-works__pic-4.png" alt="">
            </div><!-- /how-works__pic -->

            <div class="how-works__txt" v-html="$t('howWorks.item4')"></div><!-- /how-works__txt -->

          </div><!-- /how-works__item -->

          <div class="how-works__item how-works__item_5">

            <div class="how-works__pic">
              <img src="../assets/new/img/how-works/how-works__pic-5.png" alt="">
            </div><!-- /how-works__pic -->

            <div class="how-works__txt" v-html="$t('howWorks.item5')"></div><!-- /how-works__txt -->

          </div><!-- /how-works__item -->

        </div><!-- /how-works__wrap -->

        <div class="site__btn">
          <a :href="links.registration" class="btn" target="_blank">
            {{ $t('buttons.signUp') }}
          </a>
        </div><!-- /site__btn -->

      </div><!-- /site__centered -->

    </section><!-- /how-works -->

    <section class="about-us">

      <div class="site__centered">

        <site-title addedClass="site__title_duplicate">
          {{ $t('about.title') }}
        </site-title>

        <div class="about-us__wrap">

          <h3 class="about-us__title">Distribune</h3><!-- /about-us__title -->

          <div class="about-us__txt" v-html="$t('about.text')"></div><!-- /about-us__txt -->

        </div><!-- /about-us__wrap -->

      </div><!-- /site__centered -->

    </section><!-- /about-us -->

    <Footer></Footer>

  </div>

</template>

<script>
import Header from "../components/TheHeader";
import Footer from "../components/TheFooter";
import SiteTitle from "../components/SiteTitile";
import IntroBGUrl from "../assets/new/img/intro/intro__bg.jpg";
import Swiper from "swiper";
import "swiper/css/swiper.min.css";

export default {
  name: 'HelloWorld',
  components: {
    Header,
    Footer,
    SiteTitle,
  },
  data() {
    return {
      intro: {
        IntroBGUrl
      },
      slider: {
        wrap: '',
        container: '',
        pagination: '',
      },
      ru: false,
    }
  },
  methods: {
    setVariables() {
      this.slider.wrap = this.$refs.slider;
      this.slider.container = this.slider.wrap.querySelector('.swiper-container');
      this.slider.pagination = this.slider.wrap.querySelector('.swiper-pagination');
    },
    initSwiper() {
      this.setVariables();
      const mySwiper = new Swiper(this.slider.container, {
        slidesPerView: 1,
        spaceBetween: 0,
        autoHeight: true,
        autoplay: {
          delay: 6000,
          disableOnInteraction: false,
        },
        pagination: {
          el: this.slider.pagination,
          clickable: true,
        },
        speed: 800
      });
      mySwiper.init();
    },
    switchL() {
      this.$root.$i18n.locale === 'ru' ? this.ru = true : this.ru = false;
    }
  },
  computed: {
    links() {
      return this.$store.state.static.routePaths
    }
  },
  mounted() {
    this.initSwiper();
    this.$root.$i18n.locale === 'ru' ? this.ru = true : this.ru = false;
  }
}
</script>

<style lang="scss" scoped>

</style>
